const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload,
      };
    case 'SET_BODY_FIXED':
      return {
        ...state,
        body_fixed: action.body_fixed,
      };
    case 'SET_DEVELOPMENT':
      return {
        ...state,
        development: action.payload,
      };
    case 'SET_SEARCH_RADIUS':
      return {
        ...state,
        searchRadius: action.payload,
      };
    case 'SET_SEARCH_GEOLOCATION':
      return {
        ...state,
        searchGeoLocation: action.payload,
      };
    case 'SET_SEARCH_LOCATION_READABLE':
      return {
        ...state,
        searchLocationReadable: action.payload,
      };
    case 'SET_LOADER':
      return {
        ...state,
        showLoader: action.payload,
      };
    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
