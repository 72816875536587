import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';
import PropTypes from 'prop-types';

const initialState = {
  menu: false,
  body_fixed: false,
  development: '',
  searchRadiusOptions: [5, 10, 25, 50, 75, 100],
  searchRadius: 5,
  searchGeoLocation: { lat: '', lng: '' },
  searchLocationReadable: '',
  showLoader: false,
  searchResults: [],
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Context = createContext(initialState);
export default Store;
