import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Myriad Pro Regular, sans-serif',
    fontWeight: 'normal',
    htmlFontSize: 10,
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#BEA169',
    },
    secondary: {
      main: '#3E3C3E',
    },
    background: {
      default: '#282827',
      primary: '#3E3C3E',
      secondary: '#000',
    },
    text: {
      primary: '#BEA169',
      secondary: '#FFF',
      tonegrey: '#282827',
    },
  },
});

theme.overrides = {
  MuiContainer: {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3vw',
        paddingRight: '3vw',
      },
    },
  },
  MuiCssBaseline: {
    '@global': {},
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: '1.1rem',
    },
    h1: {
      fontSize: '4.25rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '4.25rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '4.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.25rem',
      },
    },
    h2: {
      fontSize: '3.25rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '3.25rem',
      },
    },
    h3: {
      fontSize: '2.25rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2.25rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '2.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.25rem',
      },
    },
    h4: {
      fontSize: '2rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    h5: {
      fontSize: '1.4rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.4rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
    },
    h6: {
      fontSize: '1rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    body1: {
      fontSize: '1.2rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.2rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    body2: {
      fontSize: '1rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    button: {
      fontSize: '1rem',
      textTransform: 'inherit',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    caption: {
      fontSize: '0.85rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.85rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.85rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
      },
    },
    subtitle1: {
      letterSpacing: '2px',
      fontSize: '0.95rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.95rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.95rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.95rem',
      },
    },
  },
};

//theme = responsiveFontSizes(theme);

export default theme;
